@import url(https://fonts.bunny.net/css?family=abril-fatface:400|rubik:600,800i,900i);

.App {
    min-height: 100vh;
    background-color: #101725;
}

.container {
    width: 350px;
    margin: 0 auto;
    padding-top: 100px;
}

.generator {
    background-color: #0b0f19;
    border-radius: 3px;
    box-shadow: 0px 2px 10px rgba(255, 255, 255, 0.2);
    padding: 20px;
}

.generator__header {
    text-align: center;
    color: white;
    margin-bottom: 20px;
    font-family: 'Abril Fatface', display;
    font-family: 'Rubik', sans-serif;
}

.generator__password {
    position: relative;
    background-color: #101725;
    padding: 13px 10px;
    color: white;
    height: 46px;
    margin-bottom: 15px;
    font-family: 'Abril Fatface', display;
    font-family: 'Rubik', sans-serif;
}

.copy__btn {
    position: absolute;
    /* background-color: #3b3b98; */
    color: white;
    border: none;
    height: 40px;
    padding: 10;
    cursor: pointer;
    top: 3px;
    right: 3x;
}

.generator__btn {
    background-color: #101725;
    border: none;
    display: block;
    width: 100%;
    padding: 10px;
    color: white;
    font-size: 17px;
    cursor: pointer;
    font-family: 'Abril Fatface', display;
    font-family: 'Rubik', sans-serif;
}

.form-group {
    display: flex;
    justify-content: space-between;
    color: white;
    margin-bottom: 15px;
    font-family: 'Abril Fatface', display;
    font-family: 'Rubik', sans-serif;
}

.passgen-text {
    font-family: 'Abril Fatface', display;
    font-family: 'Rubik', sans-serif;
}

a:link {
    color: white;
}

a:visited {
    color: white;
}

a:hover {
    color: white;
}

a:active {
    color: white;
}
  
a:link, a:visited {
    text-decoration: none; 
}

a:hover, a:active {
    text-decoration: underline;
}

footer .title {
    margin-top: 40px;
    text-align: center;
    color: #0b0f19;
    font-family: 'Abril Fatface', display;
    font-family: 'Rubik', sans-serif;
}

footer .subtitle {
    margin-top: 4px;
    text-align: center;
    color: #0b0f19;
    font-family: 'Abril Fatface', display;
    font-family: 'Rubik', sans-serif;
}